import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userServices from './usersService';
import { logoutError } from '../auth/authService';

const users = JSON.parse(localStorage.getItem('users'));

const initialState = {
  users: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

if (users) {
  initialState.users = users;
}

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user.id;

    const response = await userServices.fetchUsers(token, id);
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      logoutError();
      window.location.href = '/login';
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchUnverifiedUsers = createAsyncThunk('users/fetchUnverifiedUsers', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user.id;

    const response = await userServices.fetchUnverifiedUsers(token, id);
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      logoutError();
      window.location.href = '/login';
    }
    return thunkAPI.rejectWithValue(message);
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.users = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnverifiedUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.users = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = usersSlice.actions;
export default usersSlice.reducer;
