import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
// components
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { forgotPasswordAgent, forgotPasswordSurveyor, login, loginAgent, reset } from '../../features/auth/authSlice';
import { forgotPasswordSchema } from '../../utils/Schemas';
// ----------------------------------------------------------------------

export default function ForgotPasswordForm({ user: userType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, message } = useSelector((state) => state.agentAuth);

  const { values, errors, touched, isSubmitting, setSubmitting, handleBlur, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        Email: '',
      },
      validationSchema: forgotPasswordSchema,
      onSubmit: (values) => {
        if (userType === 'Agent') {
          dispatch(forgotPasswordAgent(values));
        } else {
          dispatch(forgotPasswordSurveyor(values));
        }
      },
    });

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          setSubmitting(false);
          dispatch(reset());
        },
      });
    }

    if (isSuccess) {
      toast.success(message, {
        onClose: () => {
          setSubmitting(false);
          resetForm();
          dispatch(reset());
        },
      });
      dispatch(reset());
    }
  }, [isError, isSuccess, dispatch, message]);

  return (
    <>
      <Box onSubmit={handleSubmit} component="form" noValidate autoComplete="off" sx={{ marginTop: '3%' }}>
        <Stack spacing={1}>
          <TextField
            name="Email"
            id="Email"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Email address"
            value={values.Email}
          />
          {errors.Email && touched.Email && (
            <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Email}</Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link to="/login" variant="subtitle2" underline="hover" className="text-blue-800">
            Login
          </Link>
          <Link to="/register" variant="subtitle2" underline="hover">
            Don't have an account? <span className="text-blue-800">join now</span>
          </Link>
        </Stack>

        <Button
          type="submit"
          variant="contained"
          disableElevation
          className="bg-blue-800 w-full"
          color="primary"
          size="medium"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Please wait...' : 'Proceed'}
        </Button>
      </Box>
    </>
  );
}
