import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import UnverifiedDocuments from './pages/UnverifiedDocuments';
import VerifiedDocuments from './pages/VerifiedDocuments';
import IssuedSurvey from './pages/IssuedSurvey';
import RegisterPage from './pages/RegisterPage';
import ResolvedSurveys from './pages/ResolvedSurveys';
import UnresolvedSurveys from './pages/UnresolvedSurveys';
import PropertyDocument from './pages/PropertyDocument';
import LandDocument from './pages/LandDocument';
import SurveyPropertyDocuments from './pages/SurveyPropertyDocuments';
import SurveyLandDocuments from './pages/SurveyLandDocuments';
import ForgotPassword from './pages/ForgotPassword';
import Settings from './pages/Settings';
import ChangePassword from './pages/ChangePassword';
import VerifyEmail from './pages/VerifyEmail';

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useSelector((state) => state.agentAuth);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'settings', element: <Settings /> },
        { path: 'change-password', element: <ChangePassword /> },

        { path: 'unverifiedDocuments', element: <UnverifiedDocuments /> },
        { path: 'verifiedDocuments', element: <VerifiedDocuments /> },
        { path: 'resolvedSurveys', element: <ResolvedSurveys /> },
        { path: 'unresolvedsurveys', element: <UnresolvedSurveys /> },

        { path: 'issuedSurvey', element: <IssuedSurvey /> },
        { path: 'documents/land_document/:docId', element: <LandDocument /> },
        { path: 'documents/property_document/:docId', element: <PropertyDocument /> },
        { path: 'documents/survey_property_document/:docId', element: <SurveyPropertyDocuments /> },
        { path: 'documents/survey_land_document/:docId', element: <SurveyLandDocuments /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },

    {
      path: 'verify/:type/:userId/:uniqueString',
      element: <VerifyEmail />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
