import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import docServices from './propertydocumentService';

const docs = JSON.parse(localStorage.getItem('docs'));

const initialState = {
  docs: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const fetchUnverifiedPropertyDocsBySurveyor = createAsyncThunkWithHandler(
  'documents/fetchUnverifiedPropertyDocsBySurveyor',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.License;
    const id = thunkAPI.getState().agentAuth.user._id;

    const response = await docServices.fetchUnverifiedPropertyDocsBySurveyor(token, id, license);

    return response;
  }
);

export const fetchUnverifiedPropertyDocsByAgent = createAsyncThunkWithHandler(
  'documents/fetchUnverifiedPropertyDocsByAgent',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.AgentLicense;
    const id = thunkAPI.getState().agentAuth.user._id;

    const response = await docServices.fetchUnverifiedPropertyDocsByAgent(token, id, license);

    return response;
  }
);

export const fetchUnresolvedSurveyBySurveyor = createAsyncThunkWithHandler(
  'documents/fetchUnresolvedSurveyBySurveyor',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.License;
    const id = thunkAPI.getState().agentAuth.user._id;
    const response = await docServices.fetchUnresolvedSurveyBySurveyor(token, id, license);

    return response;
  }
);

export const fetchPendingDocsBySurveyor = createAsyncThunkWithHandler(
  'documents/fetchPendingDocsBySurveyor',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user.id;
    const response = await docServices.fetchPendingDocsBySurveyor(token, id);

    return response;
  }
);

export const fetchVerifiedPropertyDocsBySurveyor = createAsyncThunkWithHandler(
  'documents/fetchVerifiedPropertyDocsBySurveyor',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.License;
    const id = thunkAPI.getState().agentAuth.user._id;
    const response = await docServices.VerifiedDocs(token, id, license);

    return response;
  }
);

export const fetchVerifiedPropertyDocsByAgent = createAsyncThunkWithHandler(
  'documents/fetchVerifiedPropertyDocsByAgent',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.AgentLicense;
    const id = thunkAPI.getState().agentAuth.user._id;
    const response = await docServices.VerifiedDocsByAgent(token, id, license);

    return response;
  }
);

export const FetchResolvedSurveys = createAsyncThunkWithHandler(
  'documents/FetchResolvedSurveys',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.License;
    const id = thunkAPI.getState().agentAuth.user._id;
    const response = await docServices.FetchResolvedSurveys(token, id, license);

    return response;
  }
);

const propertydocumentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnverifiedPropertyDocsBySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedPropertyDocsBySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedPropertyDocsBySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnverifiedPropertyDocsByAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedPropertyDocsByAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedPropertyDocsByAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(FetchResolvedSurveys.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FetchResolvedSurveys.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(FetchResolvedSurveys.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnresolvedSurveyBySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnresolvedSurveyBySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnresolvedSurveyBySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchPendingDocsBySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPendingDocsBySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchPendingDocsBySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchVerifiedPropertyDocsBySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVerifiedPropertyDocsBySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchVerifiedPropertyDocsBySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchVerifiedPropertyDocsByAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVerifiedPropertyDocsByAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchVerifiedPropertyDocsByAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = propertydocumentsSlice.actions;
export default propertydocumentsSlice.reducer;
