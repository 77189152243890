import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import docServices from './landdocumentService';

const docs = JSON.parse(localStorage.getItem('docs'));

const initialState = {
  docs: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const fetchUnverifiedLandDocsBySurveyor = createAsyncThunkWithHandler(
  'documents/fetchUnverifiedDocs',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.License;
    const id = thunkAPI.getState().agentAuth.user._id;
    const response = await docServices.fetchUnverifiedLandDocsBySurveyor(token, id, license);
    return response;
  }
);

export const fetchUnverifiedLandDocsByAgent = createAsyncThunkWithHandler(
  'documents/fetchUnverifiedLandDocsByAgent',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.AgentLicense;
    const id = thunkAPI.getState().agentAuth.user._id;

    const response = await docServices.fetchUnverifiedLandDocsByAgent(token, id, license);

    return response;
  }
);

export const fetchUnresolvedLandSurveyBySurveyor = createAsyncThunkWithHandler(
  'documents/fetchUnresolvedLandSurveyBySurveyor',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.License;
    const id = thunkAPI.getState().agentAuth.user._id;

    const response = await docServices.fetchUnresolvedLandSurveyBySurveyor(token, id, license);

    return response;
  }
);

export const fetchPendingDocsBySurveyor = createAsyncThunkWithHandler(
  'documents/fetchPendingDocsBySurveyor',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user.id;

    const response = await docServices.fetchPendingDocsBySurveyor(token, id);

    return response;
  }
);

export const fetchhVerifiedLandDocsBySurveyor = createAsyncThunkWithHandler(
  'documents/fetchhVerifiedLandDocsBySurveyor',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.License;
    const id = thunkAPI.getState().agentAuth.user._id;

    const response = await docServices.VerifiedDocs(token, id, license);

    return response;
  }
);

export const fetchhVerifiedLandDocsByAgent = createAsyncThunkWithHandler(
  'documents/fetchhVerifiedLandDocsByAgent',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.AgentLicense;
    const id = thunkAPI.getState().agentAuth.user._id;

    const response = await docServices.VerifiedDocsByAgent(token, id, license);

    return response;
  }
);

export const FetchResolvedLandSurveys = createAsyncThunkWithHandler(
  'documents/FetchResolvedLandSurveys',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const license = thunkAPI.getState().agentAuth.user.License;
    const id = thunkAPI.getState().agentAuth.user._id;
    const response = await docServices.FetchResolvedSurveys(token, id, license);

    return response;
  }
);

const landocumentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnverifiedLandDocsBySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedLandDocsBySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedLandDocsBySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnverifiedLandDocsByAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedLandDocsByAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedLandDocsByAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(FetchResolvedLandSurveys.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FetchResolvedLandSurveys.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(FetchResolvedLandSurveys.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnresolvedLandSurveyBySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnresolvedLandSurveyBySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnresolvedLandSurveyBySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchPendingDocsBySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPendingDocsBySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchPendingDocsBySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchhVerifiedLandDocsBySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchhVerifiedLandDocsBySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchhVerifiedLandDocsBySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchhVerifiedLandDocsByAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchhVerifiedLandDocsByAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchhVerifiedLandDocsByAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = null;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = landocumentsSlice.actions;
export default landocumentsSlice.reducer;
