import { Lock, Person } from '@mui/icons-material';
import { Box, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import UpdateDetailsForm from '../sections/auth/UpdateDetailsForm';
import ChangePasswordForm from '../sections/auth/ChangePasswordForm';

const ChangePassword = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom fontWeight="bold">
        Account Settings
      </Typography>

      <Grid container spacing={3}>
        {/* Password Change Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Lock color="secondary" sx={{ mr: 1 }} />
              <Typography variant="h6" fontWeight="bold">
                Change Password
              </Typography>
            </Box>
            <Divider sx={{ mb: 3 }} />

            <ChangePasswordForm />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChangePassword;
