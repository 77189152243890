import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { changePasswordAgent, changePasswordSurveyors, reset } from '../../features/auth/authSlice';
import { changePasswordSchema } from '../../utils/Schemas';

const ChangePasswordForm = () => {
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.agentAuth);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (user?.AgentType === 'Agent') {
        dispatch(changePasswordAgent({ ...values, agentId: user?._id }));
      } else {
        dispatch(changePasswordSurveyors({ ...values, surveyorId: user?._id }));
      }
    },
  });

  const handleClickShowPassword = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  const passwordFields = [
    {
      name: 'oldPassword',
      label: 'Current Password',
    },
    {
      name: 'newPassword',
      label: 'New Password',
    },
    {
      name: 'confirmNewPassword',
      label: 'Confirm Password',
    },
  ];

  useEffect(() => {
    if (isSuccess && message === 'Password updated successfully') {
      toast.success(message, {
        onClose: () => {
          formik.resetForm();
          dispatch(reset());
        },
      });
    }

    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
  }, [isError, isSuccess, isLoading]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {passwordFields.map((field) => (
          <Grid item xs={12} key={field.name}>
            <TextField
              fullWidth
              id={field.name}
              name={field.name}
              label={field.label}
              type={showPassword[field.name] ? 'text' : 'password'}
              value={formik.values[field.name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
              helperText={formik.touched[field.name] && formik.errors[field.name]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClickShowPassword(field.name)}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword[field.name] ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="bg-blue-800"
            color="primary"
            size="medium"
            disabled={isLoading || !formik.isValid}
          >
            {isLoading ? 'Changing Password...' : 'Change Password'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePasswordForm;
