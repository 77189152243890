import axios from 'axios';
import { API_URL } from '../api';

const uploadDeedOfSaleDocument = async (token, data, formData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${API_URL}/agents/agent/draft_deed_of_sale/${data.Docid}/${data.Agentid}`,
    formData,
    config
  );

  return response.data;
};

const docServices = {
  uploadDeedOfSaleDocument,
};

export default docServices;
