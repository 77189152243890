import axios from 'axios';
import { API_URL } from '../api';

const fetchUnverifiedLandDocsBySurveyor = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/unverified_land/${License}/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnverifiedLandDocsByAgent = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/agents/agent/unverified_land/${License}/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnresolvedLandSurveyBySurveyor = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/unresolved_surveyed_land/${License}/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const VerifiedDocs = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/verified_land/${License}/${id}`, config);

  return response.data;
};

const VerifiedDocsByAgent = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/agents/agent/verified_land/${License}/${id}`, config);

  return response.data;
};

const FetchResolvedSurveys = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/resolved_surveyed_land/${License}/${id}`, config);

  return response.data;
};

const fetchPendingDocs = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/pending_land/${id}`, config);
  return response.data;
};

const docServices = {
  fetchUnverifiedLandDocsBySurveyor,
  fetchPendingDocs,
  VerifiedDocs,
  FetchResolvedSurveys,
  fetchUnresolvedLandSurveyBySurveyor,
  fetchUnverifiedLandDocsByAgent,
  VerifiedDocsByAgent,
};

export default docServices;
