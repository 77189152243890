import axios from 'axios';

// const API_URL = 'http://localhost:5000/LYL/users/';

const API_URL = 'https://lockyourlands.uw.r.appspot.com/LYL/users/';

const getUser = async (token, id, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}user/${userId}/${id}`, config);

  if (response.data) {
    localStorage.setItem('usersFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};

const updateUserStatus = async (token, id, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}user/verify/${userId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('usersFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};

const UnverifyUserStatus = async (token, id, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}user/unverify/${userId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('usersFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};
const userService = { getUser, updateUserStatus, UnverifyUserStatus };
export default userService;
