import React, { useEffect } from 'react';
import { Box, Chip, Container, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/loader';
import { fetchPendingDocsBySurveyor, reset } from '../features/propertydocuments/propertydocumentSlice';
import notFound from '../assets/notFound.svg';

const IssuedSurvey = () => {
  const dispatch = useDispatch();
  const pendingImg = '/assets/icons/pending.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const viewImg = '/assets/icons/search.png';

  const { docs, isLoading, isError, message } = useSelector((state) => state.propertydocuments);

  useEffect(() => {
    dispatch(fetchPendingDocsBySurveyor());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          reset();
        },
      });
    }
  }, [isError, message]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'Firstname',
      headerName: 'Firstname',
      width: 100,
    },
    {
      field: 'Lastname',
      headerName: 'Lastname',
      width: 100,
    },
    {
      field: 'Email',
      headerName: 'Email',
      width: 170,
    },
    {
      field: 'Property',
      headerName: 'Property',
      width: 210,
    },

    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Chip
              variant="contained"
              color={`${params.row.Status === 'pending' ? 'warning' : 'error'}`}
              label={`${params.row.Status === 'pending' ? 'Pending' : 'Failed'}`}
              icon={
                <img
                  src={params.row.Status === 'pending' ? pendingImg : unverifiedImg}
                  alt="verified_img"
                  className="h-4 w-4"
                />
              }
              size="small"
              sx={{ cursor: 'pointer' }}
            />
          </>
        );
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/dashboard/documents/document/${params.row.docId}`}>
              <Chip
                variant="contained"
                color="info"
                label="View"
                icon={<img src={viewImg} alt="verified_img" className="h-4 w-4" />}
                size="small"
                sx={{ cursor: 'pointer' }}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const rows = [];
  docs?.map((doc, index) => {
    return rows.push({
      docId: doc.document._id,
      id: index + 1,
      Firstname: doc.user.Firstname,
      Lastname: doc.user.Lastname,
      Email: doc.user.Email,
      Status: doc.document.Status,
      Property: `${doc.property.Address}, ${doc.property.Country},  ${doc.property.State} `,
    });
  });

  return (
    <>
      <Helmet>
        <title> Survery | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Issued Surveys
          </Typography>
        </Stack>
        {isLoading ? (
          <Box sx={{ height: 400, width: '100%' }} className="flex items-center justify-center">
            <Loader />
          </Box>
        ) : (
          <Box sx={{ height: 400, width: '100%' }} className="flex items-center justify-center">
            {docs === null || docs?.length < 1 ? (
              <img src={notFound} alt="not found" className="w-72" />
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            )}
          </Box>
        )}
      </Container>
    </>
  );
};

export default IssuedSurvey;
