import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { uploadDeedOfSaleDocument } from '../../features/generaldocument/generaldocumentSlice';
import { deedOfSaleSchema } from '../../utils/Schemas';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function DeedOfSaleModal({ open, handleClose, TypeOfDocument }) {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const { user } = useSelector((state) => state.agentAuth);
  const { isLoading } = useSelector((state) => state.generaldocument);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      deedOfSale: '',
    },
    validationSchema: deedOfSaleSchema,
    onSubmit: (values, actions) => {
      const formData = new FormData();
      formData.append('deedOfSale', values.deedOfSale);
      formData.append('License', user?.AgentLicense);
      formData.append('TypeOfDocument', TypeOfDocument);
      dispatch(
        uploadDeedOfSaleDocument({
          formData,
          Docid: docId,
          Agentid: user?._id,
        })
      );
    },
  });
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload Deed of Sale
          </Typography>
          <Box
            onSubmit={isSubmitting ? () => {} : handleSubmit}
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              padding: 0,
              margin: '5% 0%',
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <h3>Deed Of Sale Upload</h3>
                <div className="border-2 p-2 ">
                  <input
                    id="deed of sale"
                    type="file"
                    style={{ display: '' }}
                    accept=".pdf"
                    onChange={(e) => setFieldValue('deedOfSale', e.target.files?.[0] || null)}
                  />
                </div>

                {errors.deedOfSale && touched.deedOfSale && (
                  <Typography sx={{ fontSize: '12px', color: 'red' }}>{errors.deedOfSale}</Typography>
                )}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              disabled={isLoading}
              sx={{
                bgcolor: '#1e40af',
                textTransform: 'none',
                fontWeight: 'bold',
                marginTop: '3%',
              }}
              className="bg-blue-700 w-full"
              size="medium"
              type="submit"
            >
              {isLoading ? 'Please wait...' : 'Upload'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
