import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress, Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { reset, verifyAgent, verifySurveyor } from '../features/auth/authSlice';

const VerifyEmail = () => {
  const { userId, uniqueString, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isError, isSuccess, message } = useSelector((state) => state.agentAuth);

  useEffect(() => {
    if (type === 'agent') {
      dispatch(verifyAgent({ id: userId, string: uniqueString }));
    } else {
      dispatch(verifySurveyor({ id: userId, string: uniqueString }));
    }
  }, [dispatch, userId, uniqueString]);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          dispatch(reset());
          navigate('/register');
        },
      });
    }

    if (isSuccess || user) {
      toast.success('Congratulations! Your email has been verified successfully', {
        onClose: () => {
          dispatch(reset());
          navigate('/login');
        },
      });
    }
  }, [isError, isSuccess, user, message, dispatch, navigate]);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          padding: 3,
        }}
      >
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'background.paper',
            borderRadius: 2,
            padding: 4,
            boxShadow: 3,
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: 'primary.main',
              marginBottom: 3,
            }}
          >
            Email Verification
          </Typography>

          <CircularProgress size={48} thickness={4} sx={{ marginBottom: 2 }} />

          <Typography
            variant="body1"
            sx={{
              color: 'text.secondary',
              marginTop: 2,
            }}
          >
            Please wait while we verify your email address...
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyEmail;
