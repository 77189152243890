import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import documentService from './propertydocumentService';
import { createAsyncThunkWithHandler } from '../api';

const initialState = {
  propertydocument: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

export const getDocument = createAsyncThunkWithHandler('document/getDocument', async (documentId, thunkAPI) => {
  const token = thunkAPI.getState().agentAuth.user.token;
  const id = thunkAPI.getState().agentAuth.user._id;
  const data = await documentService.getDocument(token, id, documentId);
  return data;
});

export const getDocumentByAgent = createAsyncThunkWithHandler(
  'document/getDocumentByAgent',
  async (documentId, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user._id;
    const data = await documentService.getDocumentByAgent(token, id, documentId);

    return data;
  }
);

export const verifyDocumentStatusByAgent = createAsyncThunkWithHandler(
  'document/verifyDocumentStatusByAgent',
  async (value, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user._id;
    const docId = value.docId;
    const formdata = value.formData;
    const data = await documentService.verifyDocumentStatusByAgent(token, id, docId, formdata);

    return data;
  }
);

export const verifySurveyStatus = createAsyncThunkWithHandler(
  'document/verifySurveyStatus',
  async (value, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user._id;
    const license = thunkAPI.getState().agentAuth.user.License;
    const docId = value.docId;
    const formdata = value.formData;
    const data = await documentService.verifySurveyStatus(token, id, docId, formdata);

    return data;
  }
);

export const flagSurveyStatus = createAsyncThunkWithHandler('document/flagSurveyStatus', async (value, thunkAPI) => {
  const token = thunkAPI.getState().agentAuth.user.token;
  const id = thunkAPI.getState().agentAuth.user._id;
  const license = thunkAPI.getState().agentAuth.user.License;
  const docId = value.docId;
  const formdata = value.formData;
  const data = await documentService.FlagSurveyStatus(token, id, docId, formdata);

  return data;
});

export const unVerifyDocumentByAgent = createAsyncThunkWithHandler(
  'document/unVerifyDocumentByAgent',
  async (value, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user._id;
    const docId = value.docId;
    const formdata = value.formData;
    const data = await documentService.flagDocumentStatusByAgent(token, id, docId, formdata);

    return data;
  }
);

export const confirmPriceAddSellersInfo = createAsyncThunkWithHandler(
  'document/confirmPriceAddSellersInfo',
  async (value, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const id = thunkAPI.getState().agentAuth.user._id;
    const docId = value.docId;
    const sellersPriceInfo = value.data;
    const data = await documentService.confirmPriceAddSellersInfo(token, id, docId, sellersPriceInfo);

    return data;
  }
);

const propertydocumentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
      })
      .addCase(getDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(getDocumentByAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocumentByAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
      })
      .addCase(getDocumentByAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })

      .addCase(verifyDocumentStatusByAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyDocumentStatusByAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'This Document has now been Verified';
      })
      .addCase(verifyDocumentStatusByAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(verifySurveyStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifySurveyStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'This Survey has now been Verified';
      })
      .addCase(verifySurveyStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(flagSurveyStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(flagSurveyStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'This Survey has now been Unverified';
      })
      .addCase(flagSurveyStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })

      .addCase(unVerifyDocumentByAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unVerifyDocumentByAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'This Document has now been Unverified';
      })
      .addCase(unVerifyDocumentByAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(confirmPriceAddSellersInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(confirmPriceAddSellersInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'Price confirmation successful';
      })
      .addCase(confirmPriceAddSellersInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
});

export const { reset } = propertydocumentSlice.actions;
export default propertydocumentSlice.reducer;
