import { Grid, TextareaAutosize, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { flagSurveyStatus } from '../../features/propertydocument/propertydocumentSlice';
import { ConfirmSurveySchema } from '../../utils/Schemas';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function CancelSurveyModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const { document: doc, isLoading, isError, isSuccess, message } = useSelector((state) => state.propertydocument);
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      surveyPdf: '',
      Comment: '',
      UnbalancedPayment: '',
    },
    validationSchema: ConfirmSurveySchema,
    onSubmit: (values, actions) => {
      const formData = new FormData();
      formData.append('Comment', values.Comment);
      formData.append('UnbalancedPayment', values.UnbalancedPayment.replace(/,/g, ''));
      for (let index = 0; index < values.surveyPdf.length; index += 1) {
        formData.append('surveyPdf', values.surveyPdf[index]);
      }

      dispatch(flagSurveyStatus({ formData, docId }));
    },
  });

  const handleImageUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFieldValue('surveyPdf', event.target.files);
  };

  const formatNumber = (value) => {
    const number = value.replace(/\D/g, '');
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePriceChange = (event, name) => {
    const rawValue = event.target.value;
    const numericValue = rawValue.replace(/[^0-9]/g, '');
    const formattedValue = formatNumber(numericValue);

    setFieldValue(name, formattedValue);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload survey document
          </Typography>
          <Box
            onSubmit={handleSubmit}
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              padding: 0,
              margin: '5% 0%',
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <h3 htmlFor="">Comments</h3>
                <span className="text-gray-600 text-[10px] leading-tight">
                  (*) Please provide your comments, completion date, and any additional findings.
                </span>
                <div>
                  <textarea
                    label="Comment"
                    id="Comment"
                    rows={5}
                    cols={10}
                    className="w-full rounded-md outline-none border p-3"
                    onChange={handleChange}
                    value={values.Comment}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.Comment && touched.Comment && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.Comment}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <h3 htmlFor="">Balanced Payment</h3>
                  <span className="text-gray-600 text-[10px] leading-tight">
                    (*) Please enter the balance to be paid for the survery.
                  </span>
                  <TextField
                    id="UnbalancedPayment"
                    size=""
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={(e) => handlePriceChange(e, 'UnbalancedPayment')}
                    value={values.UnbalancedPayment}
                    onBlur={(e) => handlePriceChange(e, 'UnbalancedPayment')}
                  />
                </div>
                {errors.UnbalancedPayment && touched.UnbalancedPayment && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.UnbalancedPayment}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <input id="pdf" type="file" style={{ display: '' }} accept=".pdf" onChange={handleImageUpload} />
                </div>
                {errors.surveyPdf && touched.surveyPdf && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.surveyPdf}</Typography>
                )}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              disabled={isSubmitting || isLoading}
              sx={{
                bgcolor: '#1e40af',
                textTransform: 'none',
                fontWeight: 'bold',
                marginTop: '3%',
              }}
              className="bg-blue-700 w-full"
              size="medium"
              type="submit"
            >
              {isLoading ? 'Please wait...' : 'Flag Document Validity'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
