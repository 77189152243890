import axios from 'axios';

// const API_URL = 'http://localhost:5000/LYL/users/';
const API_URL = 'https://lockyourlands.uw.r.appspot.com/LYL/users/';

const fetchUsers = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}${id}`, config);

  if (response.data) {
    localStorage.setItem('users', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnverifiedUsers = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/pending/${id}`, config);

  if (response.data) {
    localStorage.setItem('users', JSON.stringify(response.data));
  }
  return response.data;
};

const userServices = {
  fetchUsers,
  fetchUnverifiedUsers,
};

export default userServices;
