import { Box, Chip, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Helmet } from 'react-helmet-async';
import { ImageViewer } from 'react-image-viewer-dv';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImageIcon } from 'lucide-react';
import Loader from '../components/loader/Loader';
import CancelModal from '../components/modal/CancelModal';
import ConfirmModal from '../components/modal/ConfirmModal';
import { getDocument, getDocumentByAgent, reset } from '../features/landdocument/landdocumentSlice';
import { getBanks } from '../features/auth/authSlice';
import { maskAddress, maskEmail, maskPhoneNumber } from '../helpers/maskedFunctions';
import ConfirmPropertyPriceModal from '../components/modal/ConfirmPropertyPriceModal';
import DeedOfSaleModal from '../components/modal/DeedOfSaleModal';
import useLoadingMessages from '../hooks/useLoadingMessage';

const LandDocument = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [qrcode, setQrCode] = useState('');
  const [openConfirmModal, setConfirmModalOpen] = React.useState(false);
  const [openCancelModal, setCancelModalOpen] = React.useState(false);
  const [openDeedOfSaleModal, setDeedOfSaleModalOpen] = React.useState(false);
  const [openConfirmPropertyPriceModal, setConfirmPropertyPriceModalOpen] = React.useState(false);

  const handleOpenDeedOfSaleModal = () => setDeedOfSaleModalOpen(true);
  const handleCloseDeedOfSaleModal = () => setDeedOfSaleModalOpen(false);

  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const handleOpenCancelModal = () => setCancelModalOpen(true);
  const handleCloseCancelModal = () => setCancelModalOpen(false);

  const handleOpenConfirmPropertyPriceModal = () => setConfirmPropertyPriceModalOpen(true);
  const handleCloseConfirmPropertyPriceModal = () => setConfirmPropertyPriceModalOpen(false);

  const verifiedImg = '/assets/icons/verified.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const pendingImg = '/assets/icons/pending.png';
  const { document: doc, isLoading, isError, isSuccess, message } = useSelector((state) => state.landdocument);
  const {
    isLoading: generalLoading,
    isError: generalError,
    isSuccess: generalSuccess,
    message: generalMessage,
  } = useSelector((state) => state.generaldocument);

  const { docId } = useParams();
  const { user } = useSelector((state) => state.agentAuth);

  useEffect(() => {
    if (user.AgentType === 'Agent') {
      dispatch(getDocumentByAgent(docId));
    } else {
      dispatch(getDocument(docId));
    }
    reset();
  }, []);

  useEffect(() => {
    dispatch(getBanks());
    setTimeout(() => {
      dispatch(reset());
    }, 3000);
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess && message.includes('This Document has now been Verified')) {
      toast.success(message);
      handleCloseConfirmModal();
    }

    if (isSuccess && message.includes('Price confirmation successful')) {
      toast.success(message);
      handleCloseConfirmPropertyPriceModal();
    }

    if (isSuccess && message.includes('This Document has now been Unverified')) {
      toast.error(message);
      handleCloseCancelModal();
    }

    dispatch(reset());
  }, [isError, isSuccess, message]);

  useEffect(() => {
    if (generalError) {
      toast.error(message);
    }

    if (generalSuccess && generalMessage) {
      toast.success(generalMessage);
      handleCloseDeedOfSaleModal();
      navigate(0);
    }

    dispatch(reset());
  }, [generalError, generalSuccess, generalMessage]);

  return (
    <>
      <Helmet>
        <title> Document | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Document Details
          </Typography>
        </Stack>

        {isLoading && <Loader />}
        {doc && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Buyers Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Fullname: <br /> <span className="font-bold">{doc?.user?.Firstname}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Lastname: <br /> <span className="font-bold">{doc?.user?.Lastname}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Gender: <br /> <span className="font-bold">{doc?.user?.Gender}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Phone: <br /> <span className="font-bold">{maskPhoneNumber(`${doc?.user?.Phone}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Email: <br /> <span className="font-bold">{maskEmail(`${doc?.user?.Email}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{doc?.user?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{doc?.user?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{maskAddress(`${doc?.user?.Address}`)}</span>
                      </p>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{doc?.user?.Nin}</span>
                      </p>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">Status</p>
                      <Chip
                        label={
                          doc?.user?.VerifiedUser === 'completed'
                            ? 'Verified'
                            : doc?.user?.VerifiedUser === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.user?.VerifiedUser === 'pending'
                            ? 'Pending'
                            : ''
                        }
                        size="small"
                        color={
                          doc?.user?.VerifiedUser === 'completed'
                            ? 'success'
                            : doc?.user?.VerifiedUser === 'Not Verified'
                            ? 'error'
                            : doc?.user?.VerifiedUser === 'pending'
                            ? 'warning'
                            : ''
                        }
                        icon={
                          <img
                            src={
                              doc?.user?.VerifiedUser === 'completed'
                                ? verifiedImg
                                : doc?.user?.VerifiedUser === 'Not Verified'
                                ? unverifiedImg
                                : doc?.user?.VerifiedUser === 'pending'
                                ? pendingImg
                                : ''
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* sellers information, the check is if the user isnt the seller, mostly if its for verifcation and not listings */}
            {doc?.user?._id !== doc?.seller?._id && (
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <Box className="">
                  <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                    <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                      Sellers Information: <br />
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Fullname: <br /> <span className="font-bold">{doc?.seller?.Firstname}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Lastname: <br /> <span className="font-bold">{doc?.seller?.Lastname}</span>
                        </p>
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Gender: <br /> <span className="font-bold">{doc?.seller?.Gender}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Phone: <br /> <span className="font-bold">{maskPhoneNumber(`${doc?.user?.Phone}`)}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Email: <br /> <span className="font-bold">{maskEmail(`${doc?.user?.Email}`)}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Country: <br /> <span className="font-bold">{doc?.seller?.Country}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          State: <br /> <span className="font-bold">{doc?.seller?.State}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Address: <br /> <span className="font-bold">{maskAddress(`${doc?.seller?.Address}`)}</span>
                        </p>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{doc?.seller?.Nin}</span>
                      </p>
                    </Grid> */}
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">Status</p>
                        <Chip
                          label={
                            doc?.seller?.VerifiedUser === 'completed'
                              ? 'Verified'
                              : doc?.seller?.VerifiedUser === 'Not Verified'
                              ? 'Not Verified'
                              : doc?.seller?.VerifiedUser === 'pending'
                              ? 'Pending'
                              : ''
                          }
                          size="small"
                          color={
                            doc?.seller?.VerifiedUser === 'completed'
                              ? 'success'
                              : doc?.seller?.VerifiedUser === 'Not Verified'
                              ? 'error'
                              : doc?.seller?.VerifiedUser === 'pending'
                              ? 'warning'
                              : ''
                          }
                          icon={
                            <img
                              src={
                                doc?.seller?.VerifiedUser === 'completed'
                                  ? verifiedImg
                                  : doc?.seller?.VerifiedUser === 'Not Verified'
                                  ? unverifiedImg
                                  : doc?.seller?.VerifiedUser === 'pending'
                                  ? pendingImg
                                  : ''
                              }
                              alt="verified img"
                              className="w-4 h-4"
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}

            {/* sellers information, the check is if the user isnt the seller, mostly if its for verifcation and not listings */}
            {doc?.user?._id === doc?.seller?._id && (
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <Box className="">
                  <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                    <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                      Sellers Information: <br />
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Fullname: <br /> <span className="font-bold">{doc?.document?.SellersName}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Account Number: <br />{' '}
                          <span className="font-bold">{doc?.document?.SellersAccountNumber}</span>
                        </p>
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Bank: <br /> <span className="font-bold">{doc?.document?.SellersBank}</span>
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}

            {/* land documents and images  */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Images: <br />
                  </Typography>

                  <div className="space-y-2">
                    {doc?.land?.Images?.map((image, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-md border"
                      >
                        <div className="flex items-center space-x-2">
                          <ImageIcon className="text-gray-500 h-5 w-5" />
                          <span className="text-sm">Image {index + 1}</span>
                        </div>

                        <a href={image} target="_blank" rel="noreferrer">
                          <Button variant="outline" size="sm" className="text-xs">
                            View Image
                          </Button>
                        </a>
                      </div>
                    ))}
                  </div>
                </Box>

                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Documents: <br />
                  </Typography>
                  <div className="space-y-2">
                    {doc?.land?.Documents?.map((image, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-md border"
                      >
                        <div className="flex items-center space-x-2">
                          <ImageIcon className="text-gray-500 h-5 w-5" />
                          <span className="text-sm">Document {index + 1}</span>
                        </div>

                        <a href={image} target="_blank" rel="noreferrer">
                          <Button variant="outline" size="sm" className="text-xs">
                            View Document
                          </Button>
                        </a>
                      </div>
                    ))}
                  </div>
                </Box>
              </Box>
            </Grid>

            {/* property information */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{doc?.land?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{doc?.land?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Area: <br /> <span className="font-bold">{doc?.land?.Area}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{doc?.land?.Address}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Price: <br />{' '}
                        <span className="font-bold">
                          {doc?.land?.Currency} {doc?.land?.Price.toLocaleString()}
                        </span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Escrow: <br />{' '}
                        <span className="font-bold">
                          {doc?.land?.Currency} {doc?.land?.Escrow.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Confirmed Price: <br />{' '}
                        <span className="font-bold">
                          {doc?.land?.Currency} {doc?.document?.AgentConfirmedPrice?.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Confirmed Escrow: <br />{' '}
                        <span className="font-bold">
                          {doc?.land?.Currency} {doc?.document?.AgentConfirmedEscrowPrice?.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Size: <br />{' '}
                        <span className="font-bold">
                          {doc?.land?.Size}
                          {doc?.land?.Measurement}
                        </span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        UniqueId: <br /> <span className="font-bold">{doc?.land?.UniqueId}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900"> Document Status</p>
                      <Chip
                        label={
                          doc?.document?.Status === 'completed'
                            ? 'Verified'
                            : doc?.document?.Status === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.document?.Status === 'pending'
                            ? 'Pending'
                            : ''
                        }
                        size="small"
                        color={
                          doc?.document?.Status === 'completed'
                            ? 'success'
                            : doc?.document?.Status === 'Not Verified'
                            ? 'error'
                            : doc?.document?.Status === 'pending'
                            ? 'warning'
                            : ''
                        }
                        icon={
                          <img
                            src={
                              doc?.document?.Status === 'completed'
                                ? verifiedImg
                                : doc?.document?.Status === 'Not Verified'
                                ? unverifiedImg
                                : doc?.document?.Status === 'pending'
                                ? pendingImg
                                : ''
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                    {doc?.document?.AgentUnbalancedPayment !== null && (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box>
                          <p className="text-blue-900 mt-3">
                            Payment Due: <br />{' '}
                            <span className="font-bold">{doc?.document?.AgentUnbalancedPayment.toLocaleString()}</span>
                          </p>
                        </Box>
                      </Grid>
                    )}
                    {doc?.document?.AgentUnbalancedPayment !== null && (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box>
                          <p className="text-blue-900"> Payment Status</p>
                          <Chip
                            label={
                              doc?.document?.AgentPaymentStatus === 'Full'
                                ? 'Full Payment'
                                : doc?.document?.AgentPaymentStatus === 'Half'
                                ? 'Half Payemnt'
                                : 'Unpaid'
                            }
                            size="small"
                            color={
                              doc?.document?.AgentPaymentStatus === 'Full'
                                ? 'success'
                                : doc?.document?.AgentPaymentStatus === 'Half'
                                ? 'warning'
                                : 'error'
                            }
                            icon={
                              <img
                                src={
                                  doc?.document?.AgentPaymentStatus === 'Full'
                                    ? verifiedImg
                                    : doc?.document?.AgentPaymentStatus === 'Half'
                                    ? pendingImg
                                    : unverifiedImg
                                }
                                alt="verified img"
                                className="w-4 h-4"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                    )}

                    {doc?.document?.Comment && (
                      <Grid item xs={12} sm={12} md={12}>
                        <p className="text-blue-900">
                          Comment: <br /> <span className="font-bold">{doc?.document?.Comment}</span>
                        </p>
                      </Grid>
                    )}
                  </Grid>

                  {doc?.document?.Status !== 'pending' && (
                    <Box className="mt-5">
                      <p className="text-blue-900">Document Validation Receipts: </p>

                      <Grid container spacing={4}>
                        <Grid item sm={12} md={12}>
                          <Grid container spacing={2}>
                            <Grid item sm={12} md={4}>
                              <Link to={`${doc?.document?.DocumentProof?.[0].LandRegistory}`}>
                                <Box className="p-2 rounded-md bg-green-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                    Land Registry Document
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Link to={`${doc?.document?.DocumentProof?.[1]?.ProbateRegistory}`}>
                                <Box className="p-2 rounded-md bg-blue-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                    Probate Registry Document
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Link to={`${doc?.document?.DocumentProof?.[2]?.CacSearch}`}>
                                <Box className="p-2 rounded-md bg-cyan-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>CAC Search</Typography>
                                </Box>
                              </Link>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {doc?.document?.Validation === true && (
                    <Box className="flex mt-5  ">
                      <ConfirmModal
                        open={openConfirmModal}
                        handleClose={handleCloseConfirmModal}
                        triggeredFrom="LandedProperty"
                      />
                      <CancelModal
                        open={openCancelModal}
                        handleClose={handleCloseCancelModal}
                        triggeredFrom="LandedProperty"
                      />

                      <ConfirmPropertyPriceModal
                        open={openConfirmPropertyPriceModal}
                        handleClose={handleCloseConfirmPropertyPriceModal}
                        triggeredFrom="LandedProperty"
                      />

                      {doc?.document?.Status !== 'completed' && doc?.document?.Status !== 'Not Verified' && (
                        <Button
                          className="bg-blue-700 hover:bg-blue-600 p-4 rounded-md text-white"
                          onClick={handleOpenConfirmModal}
                          disabled={isLoading}
                        >
                          {isLoading ? 'Please wait...' : 'Confirm Document Validity'}
                        </Button>
                      )}
                      {doc?.document?.Status !== 'Not Verified' && doc?.document?.Status !== 'completed' && (
                        <Button
                          className="bg-red-700 hover:bg-red-600 p-4 text-white"
                          onClick={handleOpenCancelModal}
                          disabled={isLoading}
                        >
                          {isLoading ? 'Please wait...' : 'Flag Document Validity'}
                        </Button>
                      )}
                      {doc?.document?.SellersName == null && (
                        <Button
                          className="bg-green-700 hover:bg-green-600 p-4 text-white"
                          onClick={handleOpenConfirmPropertyPriceModal}
                          disabled={isLoading}
                        >
                          {isLoading ? 'Please wait...' : 'Confirm Property Price'}
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Deed of sale */}
            {doc?.document?.FullPropertyPaymentStatus && (
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  {doc?.document?.FinalDeedOfSaleStatus === true ? (
                    <>
                      <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                        View Final Deed Of Sale
                      </Typography>
                      <Link to={`${doc?.document?.FinalDeedOfSale}`}>
                        <Box className="p-2 rounded-md bg-blue-700 text-center cursor-pointer sm:w-[30%] w-full">
                          <Typography sx={{ fontSize: '14px', color: '#fff' }}>Deed Of Sale</Typography>
                        </Box>
                      </Link>
                    </>
                  ) : (
                    <Box className="">
                      <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                        {doc?.document?.AgentDeedOfSaleStatus === false
                          ? 'Upload Draft Deed Of Sale:'
                          : doc?.document?.BuyersDeedOfSaleStatus === false && doc?.document?.AgentDeedOfSaleStatus
                          ? 'Awaiting Buyers Signed Document'
                          : 'Upload Final Deed Of Sale:'}{' '}
                        <br />
                      </Typography>
                      <Box className="">
                        <DeedOfSaleModal
                          open={openDeedOfSaleModal}
                          handleClose={handleCloseDeedOfSaleModal}
                          TypeOfDocument={doc?.document?.AgentDeedOfSaleStatus === false ? 'Draft' : 'Final'}
                        />

                        {doc?.document?.AgentDeedOfSaleStatus === true &&
                        doc?.document?.BuyersDeedOfSaleStatus === false ? (
                          <Button className="bg-gray-400 hover:bg-gray-400 p-4 outline-none sm:w-[30%] w-full rounded-md text-white cursor-not-allowed">
                            Awaiting Buyers Signed Document
                          </Button>
                        ) : (
                          <Button
                            className="bg-blue-700 hover:bg-blue-600 p-4 rounded-md text-white"
                            onClick={handleOpenDeedOfSaleModal}
                            disabled={isLoading}
                          >
                            {isLoading ? 'Please wait...' : 'Upload Document'}
                          </Button>
                        )}

                        {doc?.document?.AgentDeedOfSaleStatus === true && (
                          <Link to={`${doc?.document?.DraftDeedOfSale}`}>
                            <Box className="p-2 rounded-md bg-green-700 my-3 sm:w-[30%] w-full text-center cursor-pointer">
                              <Typography sx={{ fontSize: '14px', color: '#fff' }}>View Draft Deed Of Sale</Typography>
                            </Box>
                          </Link>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default LandDocument;
