import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import { Container, Grid, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { HelpCircle } from 'lucide-react';
import {
  fetchhVerifiedLandDocsByAgent,
  fetchhVerifiedLandDocsBySurveyor,
} from '../features/landdocuments/landdocumentSlice';
import {
  fetchVerifiedPropertyDocsByAgent,
  fetchVerifiedPropertyDocsBySurveyor,
} from '../features/propertydocuments/propertydocumentSlice';
import Loader from '../components/loader/Loader';
import { AppWidgetSummary } from '../sections/@dashboard/app';

export default function DashboardAppPage() {
  const {
    user: { loggedIn, AgentType },
  } = useSelector((state) => state.agentAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { docs: propertyDocs, isLoading } = useSelector((state) => state.propertydocuments);
  const { docs: landDocs } = useSelector((state) => state.landdocuments);
  const { user } = useSelector((state) => state.agentAuth);

  // Tour state
  const [runTour, setRunTour] = useState(false);

  // Tour steps
  const steps = [
    {
      target: '.welcome-message',
      content: 'Welcome to your dashboard! This is your central control panel.',
      disableBeacon: true,
    },
    {
      target: '.verified-documents',
      content:
        AgentType === 'Agent' ? 'View all your verified documents here' : 'Check all resolved surveys in this section',
    },
    {
      target: '.unverified-documents',
      content:
        AgentType === 'Agent'
          ? 'Documents pending verification can be found here'
          : 'Unresolved surveys that need your attention are listed here',
    },
    {
      target: '.profile-button',
      content: 'Click here to view and edit your profile',
    },
    {
      target: '.tour-button',
      content: 'You can restart this tour anytime by clicking this help button',
    },
  ];

  // Check if this is the first visit
  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenDashboardTour');
    if (!hasSeenTour) {
      setRunTour(true);
      localStorage.setItem('hasSeenDashboardTour', 'true');
    }
  }, []);

  // Handle tour events
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
    }
  };

  // Original useEffect for data fetching
  useEffect(() => {
    if (user.AgentType === 'Agent') {
      dispatch(fetchVerifiedPropertyDocsByAgent());
      dispatch(fetchhVerifiedLandDocsByAgent());
    } else {
      dispatch(fetchVerifiedPropertyDocsBySurveyor());
      dispatch(fetchhVerifiedLandDocsBySurveyor());
    }
  }, []);

  // Session timeout logic
  useEffect(() => {
    const currentTime = new Date();
    const timeDifferenceInMs = currentTime - loggedIn;
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    if (timeDifferenceInHours >= 24) {
      localStorage.removeItem('Surveyuser');
      navigate('/login');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Lock your Land </title>
      </Helmet>

      {/* Tour Button */}
      <Button
        className="tour-button"
        startIcon={<HelpCircle />}
        onClick={() => setRunTour(true)}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        variant="outline"
        color="primary"
      >
        Start Tour
      </Button>

      {/* Joyride Tour */}
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: theme.palette.primary.main,
            textColor: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
          },
        }}
      />

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }} className="welcome-message">
          Hi, Welcome back
        </Typography>

        {isLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} className="verified-documents">
              <Link to={AgentType === 'Agent' ? `/dashboard/verifiedDocuments` : `/dashboard/resolvedSurveys`}>
                <AppWidgetSummary
                  title={AgentType === 'Agent' ? 'Verified Documents' : 'Resolved Surveys'}
                  color="info"
                  icon={'emojione-v1:document'}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className="unverified-documents">
              <Link to={AgentType === 'Agent' ? `/dashboard/unverifiedDocuments` : `/dashboard/unresolvedsurveys`}>
                <AppWidgetSummary
                  title={AgentType === 'Agent' ? 'Un-Verified Documents' : 'Un-Resolved Surveys'}
                  icon={'emojione-v1:document'}
                  color="success"
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="profile-button">
              <Link to="/dashboard/settings">
                <AppWidgetSummary title="View Profile" icon={'emojione-v1:document'} color="primary" />
              </Link>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}
