import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
// components
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import { login, loginAgent, reset } from '../../../features/auth/authSlice';
import { loginSchema } from '../../../utils/Schemas';
// ----------------------------------------------------------------------

export default function LoginForm({ user: userType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, message } = useSelector((state) => state.agentAuth);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Email: '',
      Password: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values, actions) => {
      if (userType === 'Agent') {
        dispatch(loginAgent(values));
      } else {
        dispatch(login(values));
      }
    },
  });

  useEffect(() => {
    if (isError) {
      if (message === 'Verification still under review') {
        toast.info(message, {
          onClose: () => {
            setSubmitting(false);
          },
        });
      } else {
        toast.error(message, {
          onClose: () => {
            setSubmitting(false);
          },
        });
      }
    }

    if (isSuccess || user) {
      navigate('/');
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Box onSubmit={handleSubmit} component="form" noValidate autoComplete="off" sx={{ marginTop: '3%' }}>
        <Stack spacing={1}>
          <TextField
            name="Email"
            id="Email"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Email address"
            value={values.Email}
          />
          {errors.Email && touched.Email && (
            <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Email}</Typography>
          )}

          <TextField
            name="Password"
            label="Password"
            id="Password"
            value={values.Password}
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.Password && touched.Password && (
            <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Password}</Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link to="/forgot-password" variant="subtitle2" underline="hover">
            Forgot password?
          </Link>
          <Link to="/register" variant="subtitle2" underline="hover">
            Don't have an account? join now
          </Link>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          disableElevation
          className="bg-blue-800 w-full"
          color="primary"
          size="medium"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Please wait...' : 'Proceed'}
        </Button>
      </Box>
    </>
  );
}
