import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import { Grid, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { confirmLandPriceAddSellersInfo } from '../../features/landdocument/landdocumentSlice';
import { confirmPriceAddSellersInfo } from '../../features/propertydocument/propertydocumentSlice';
import { ConfirmPropertyPriceSchema } from '../../utils/Schemas';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflow: 'auto',
};

export default function ConfirmPropertyPriceModal({ open, handleClose, triggeredFrom }) {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const { user, banks } = useSelector((state) => state.agentAuth);
  const { isLoading: propertyLoading } = useSelector((state) => state.propertydocument);
  const { isLoading: landLoading } = useSelector((state) => state.landdocument);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      AgentConfirmedPrice: '',
      AgentConfirmedEscrowPrice: '',
      SellersName: '',
      SellersAccountNumber: '',
      SellersBank: '',
      SellersNIN: '',
    },
    validationSchema: ConfirmPropertyPriceSchema,
    onSubmit: async (values, actions) => {
      const sanitizedValues = {
        ...values,
        AgentConfirmedPrice: values.AgentConfirmedPrice.replace(/,/g, ''),
        AgentConfirmedEscrowPrice: values.AgentConfirmedEscrowPrice.replace(/,/g, ''),
      };

      dispatch(
        (triggeredFrom === 'LandedProperty' ? confirmLandPriceAddSellersInfo : confirmPriceAddSellersInfo)({
          data: sanitizedValues,
          docId,
        })
      );
    },
  });

  const formatNumber = (value) => {
    const number = value.replace(/\D/g, '');
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePriceChange = (event, name) => {
    const rawValue = event.target.value;
    const numericValue = rawValue.replace(/[^0-9]/g, '');
    const formattedValue = formatNumber(numericValue);
    setFieldValue(name, formattedValue);
  };

  const handleBankChange = (event) => {
    setFieldValue('SellersBank', event.target.value);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Fill up the correct details in the inputs below
          </Typography>
          <Box
            onSubmit={isSubmitting ? () => {} : handleSubmit}
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              '& .MuiSelect-root': { width: '100%' },
              padding: 0,
              margin: '5% 0%',
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              {/* Property Price Information */}
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <h3>Confirmed Price</h3>
                  <span className="text-gray-600 text-[10px] leading-tight">
                    (*) Please enter the confirmed property price
                  </span>
                  <TextField
                    id="AgentConfirmedPrice"
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={(e) => handlePriceChange(e, 'AgentConfirmedPrice')}
                    value={values.AgentConfirmedPrice}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.AgentConfirmedPrice && touched.AgentConfirmedPrice && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.AgentConfirmedPrice}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <h3>Escrow Price</h3>
                  <span className="text-gray-600 text-[10px] leading-tight">(*) Please enter the escrow amount</span>
                  <TextField
                    id="AgentConfirmedEscrowPrice"
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={(e) => handlePriceChange(e, 'AgentConfirmedEscrowPrice')}
                    value={values.AgentConfirmedEscrowPrice}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.AgentConfirmedEscrowPrice && touched.AgentConfirmedEscrowPrice && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.AgentConfirmedEscrowPrice}</Typography>
                )}
              </Grid>

              {/* Seller's Information */}
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <h3>Seller's Name</h3>
                  <TextField
                    id="SellersName"
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={handleChange}
                    value={values.SellersName}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.SellersName && touched.SellersName && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.SellersName}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <h3>Seller's NIN</h3>
                  <TextField
                    id="SellersNIN"
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={handleChange}
                    value={values.SellersNIN}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.SellersNIN && touched.SellersNIN && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.SellersNIN}</Typography>
                )}
              </Grid>

              {/* Bank Information */}
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <h3>Seller's Bank</h3>
                  <FormControl fullWidth>
                    <InputLabel id="sellers-bank-label" sx={{ fontSize: 12 }}>
                      Select Bank
                    </InputLabel>
                    <Select
                      labelId="sellers-bank-label"
                      id="SellersBank"
                      value={values.SellersBank}
                      label="Select Bank"
                      onChange={handleBankChange}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: 12 } }}
                    >
                      {banks?.data?.map((bank) => (
                        <MenuItem key={bank.id || bank.code} value={bank.name} sx={{ fontSize: 12 }}>
                          {bank.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {errors.SellersBank && touched.SellersBank && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.SellersBank}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <h3>Seller's Account Number</h3>
                  <TextField
                    id="SellersAccountNumber"
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={handleChange}
                    value={values.SellersAccountNumber}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.SellersAccountNumber && touched.SellersAccountNumber && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.SellersAccountNumber}</Typography>
                )}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              disabled={propertyLoading || landLoading}
              sx={{
                bgcolor: '#1e40af',
                textTransform: 'none',
                fontWeight: 'bold',
                marginTop: '3%',
              }}
              className="bg-blue-700 w-full"
              type="submit"
            >
              {propertyLoading || landLoading ? 'Please wait...' : 'Confirm Document Validity'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
