import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import {
  Stack,
  TextField,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
// components
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { registerSchema } from '../../../utils/Schemas';
import { register, registerAgents, reset } from '../../../features/auth/authSlice';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, message, isLoading } = useSelector((state) => state.agentAuth);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      Email: '',
      Password: '',
      ConfirmPassword: '',
      Firstname: '',
      Lastname: '',
      NIN: '',
      License: '',
      Company: '',
      AgentLicense: '',
      Phone: '',
      LicenseYear: '',
      Address: '',
      Category: '',
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      if (values.Category === 'surveyor') {
        dispatch(register(values));
      } else {
        dispatch(registerAgents(values));
      }
    },
  });

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          setSubmitting(false);
        },
      });
    }

    if (isSuccess) {
      toast.success(message, {
        onClose: () => {
          navigate('/login');
          setSubmitting(false);
        },
      });
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  return (
    <>
      <Box onSubmit={handleSubmit} component="form" noValidate autoComplete="off" sx={{ marginTop: '3%' }}>
        <Grid container spacing={2} className="">
          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="Firstname"
              id="Firstname"
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              label="Firstname"
              value={values.Firstname}
            />
            {errors.Firstname && touched.Firstname && (
              <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Firstname}</Typography>
            )}
          </Grid>

          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="Lastname"
              id="Lastname"
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              label="Lastname"
              value={values.Lastname}
            />
            {errors.Lastname && touched.Lastname && (
              <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Lastname}</Typography>
            )}
          </Grid>

          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="Email"
              id="Email"
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              label="Email address"
              value={values.Email}
            />
            {errors.Email && touched.Email && (
              <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Email}</Typography>
            )}
          </Grid>
          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="Address"
              id="Address"
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              label="Address address"
              value={values.Address}
            />
            {errors.Address && touched.Address && (
              <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Address}</Typography>
            )}
          </Grid>

          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="Phone"
              id="Phone"
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              label="Phone Number"
              value={values.Phone}
            />
            {errors.Phone && touched.Phone && (
              <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Phone}</Typography>
            )}
          </Grid>

          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="NIN"
              id="NIN"
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              label="NIN"
              value={values.NIN}
            />
            {errors.NIN && touched.NIN && <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.NIN}</Typography>}
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel id="Category">Category</InputLabel>
              <Select
                labelId="Category"
                id="Category"
                label="Category"
                size="medium"
                sx={{ fontSize: '13px', width: '100%' }}
                value={values.Category}
                onChange={(e) => {
                  setFieldValue('Category', e.target.value);
                }}
                onBlur={(e) => {
                  setFieldValue('Category', e.target.value);
                }}
              >
                <MenuItem value="surveyor">Surveyor</MenuItem>
                <MenuItem value="agent"> Agent</MenuItem>
              </Select>
            </FormControl>
            {errors.Category && <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.Category}</Typography>}
          </Grid>

          {values.Category === 'surveyor' && ( // Conditionally render License fields
            <>
              <Grid item className="w-full" sm={12} md={6} lg={6}>
                <TextField
                  name="License"
                  id="License"
                  onBlur={handleBlur}
                  style={{ width: '100%' }}
                  size="small"
                  onChange={handleChange}
                  label="License Number"
                  value={values.License}
                />
                {errors.License && touched.License && (
                  <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.License}</Typography>
                )}
              </Grid>
              <Grid item className="w-full" sm={12} md={12} lg={12}>
                <div>
                  <InputLabel htmlFor="LicenseYear">License Year</InputLabel>
                  <TextField
                    name="LicenseYear"
                    id="LicenseYear"
                    autoFocus
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                    size="small"
                    type="date"
                    onChange={handleChange}
                    value={values.LicenseYear}
                    InputLabelProps={{ shrink: true }} // This ensures the label doesn't overlap.
                  />
                </div>
                {errors.LicenseYear && touched.LicenseYear && (
                  <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.LicenseYear}</Typography>
                )}
              </Grid>
            </>
          )}

          {values.Category === 'agent' && ( // Conditionally render License fields
            <>
              <Grid item className="w-full" sm={12} md={6} lg={6}>
                <TextField
                  name="AgentLicense"
                  id="AgentLicense"
                  onBlur={handleBlur}
                  style={{ width: '100%' }}
                  size="small"
                  onChange={handleChange}
                  label="Lawyer License Number"
                  value={values.AgentLicense}
                />
                {errors.AgentLicense && touched.AgentLicense && (
                  <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.AgentLicense}</Typography>
                )}
              </Grid>
            </>
          )}

          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="Company"
              id="Company"
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              label="Your company name (optional)"
              value={values.Company}
            />
          </Grid>

          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="Password"
              label="Password"
              id="Password"
              value={values.Password}
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              type="password"
            />
            {errors.Password && touched.Password && (
              <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.Password}</Typography>
            )}
          </Grid>

          <Grid item className="w-full" sm={12} md={6} lg={6}>
            <TextField
              name="ConfirmPassword"
              label="ConfirmPassword"
              id="ConfirmPassword"
              value={values.ConfirmPassword}
              onBlur={handleBlur}
              style={{ width: '100%' }}
              size="small"
              onChange={handleChange}
              type="password"
            />
            {errors.ConfirmPassword && touched.ConfirmPassword && (
              <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors.ConfirmPassword}</Typography>
            )}
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link className="text-blue-800" to="/login" variant="subtitle2" underline="hover">
            Already have an account? Login
          </Link>
        </Stack>

        <Button
          type="submit"
          variant="contained"
          disableElevation
          className="bg-blue-800 w-full"
          color="primary"
          size="medium"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Please wait...' : 'Proceed'}
        </Button>
      </Box>
    </>
  );
}
