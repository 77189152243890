import axios from 'axios';
import { API_URL } from '../api';

const fetchUnverifiedPropertyDocsBySurveyor = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/unverified_property/${License}/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnverifiedPropertyDocsByAgent = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/agents/agent/unverified_property/${License}/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnresolvedSurveyBySurveyor = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${API_URL}/surveyors/surveyor/unresolved_surveyed_property/${License}/${id}`,
    config
  );

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const VerifiedDocs = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/verified_property/${License}/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const VerifiedDocsByAgent = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/agents/agent/verified_property/${License}/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const FetchResolvedSurveys = async (token, id, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/resolved_surveyed_property/${License}/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchPendingDocs = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/surveyor/pending_property/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const docServices = {
  fetchUnverifiedPropertyDocsBySurveyor,
  fetchUnverifiedPropertyDocsByAgent,
  fetchPendingDocs,
  VerifiedDocs,
  FetchResolvedSurveys,
  fetchUnresolvedSurveyBySurveyor,
  VerifiedDocsByAgent,
};

export default docServices;
