import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Logo from '../components/logo';
import useResponsive from '../hooks/useResponsive';
import ForgotPasswordForm from '../sections/auth/ForgotPasswordForm';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ForgotPassword() {
  const mdUp = useResponsive('up', 'md');
  const [user, setUser] = useState('Agent');

  const tabs = [
    { id: 'Agent', color: 'bg-blue-600', hoverColor: 'hover:bg-blue-700' },
    { id: 'Surveyor', color: 'bg-green-600', hoverColor: 'hover:bg-green-700' },
  ];

  return (
    <>
      <Helmet>
        <title> Forgot Password </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Forgotten Password?
            </Typography>
            <img src="/assets/illustrations/illustration_survey.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Box className="flex rounded-lg overflow-hidden shadow-md mb-6">
              {tabs.map((tab) => (
                <Box
                  key={tab.id}
                  className={`flex-1 py-3 text-center cursor-pointer transition-all duration-300 ${
                    user === tab.id
                      ? `${tab.color} text-white font-bold`
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => setUser(tab.id)}
                >
                  <Typography
                    sx={{
                      fontWeight: user === tab.id ? 'bold' : 'normal',
                      transition: 'all 0.3s ease',
                    }}
                  >
                    {tab.id}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Typography variant="h4" gutterBottom>
              Enter email to recover password as {user}
            </Typography>

            <ForgotPasswordForm user={user} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
